import {
  Row,
  Col,
  Container,
  Card,
  Navbar,
  Nav,
} from "react-bootstrap";
import Ugly from '../assets/ugly.jpg'


function Home() {

  return (
    <>
<Navbar variant="dark" sticky="top" expand="md">
  <Container fluid>
    <Navbar.Brand>
        <h1 style={{color: '#F8DE4F'}}>$UGLY</h1>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
      <Nav className="ml-auto">
        <Nav.Link className="text-center">
          <h3 style={{color: '#F8DE4F'}} href="#" onClick={() => window.open("https://t.me/uglyercportal", "_blank")}>TELEGRAM</h3>
        </Nav.Link>
        <Nav.Link className="text-center">
          <h3 style={{color: '#F8DE4F'}} href="#" onClick={() => window.open("https://twitter.com/uglyERC", "_blank")}>TWITTER</h3>
        </Nav.Link>
        <Nav.Link className="text-center">
          <h3 style={{color: '#F8DE4F'}} href="#" onClick={() => window.open("https://www.dextools.io/app/en/ether/pair-explorer/0x01bfe967a2949ab9b50a365b97a862c4ed3b0776", "_blank")}>CHART</h3>
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>

<Container fluid style={{color: '#F8DE4F'}} className="text-center">
  <h1 style={{fontSize: window.innerWidth > 600 ? 120 : 50, color: '#F8DE4F'}}>TICKER IS $UGLY</h1>
  <img src={Ugly} style={{width: window.innerWidth > 600 ? '60vh' : '100%', marginBottom: 30}} />
  <h1 style={{fontSize: window.innerWidth > 600 ? 80 : 30, color: '#F8DE4F'}}>$UGLY CONTRACT ADDRESS</h1>
  <h1 style={{fontSize: window.innerWidth > 600 ? 80 : 25, color: '#F8DE4F'}}>0x4926e2346c164fd2BD0d62C260a22725e86E664E</h1>
</Container>

    </>
  );
}

export default Home;
